var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CCard',{staticClass:"mb-0",staticStyle:{"margin-top":"0.5rem"}},[_c('CButton',{staticClass:"text-left shadow-none card-header",attrs:{"block":"","color":"link"},on:{"click":function($event){_vm.accordionHeader = _vm.accordionHeader === 0 ? false : 0}}},[_c('h5',{staticClass:"m-0"},[_vm._v("Datos de pago")])]),_c('CCollapse',{attrs:{"show":_vm.accordionHeader === 0}},[_c('CCardBody',[_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.flagErr),callback:function ($$v) {_vm.flagErr=$$v},expression:"flagErr"}},[_vm._v(" "+_vm._s(_vm.errMsg)+" ")]),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{staticStyle:{"text-align":"center"}},[_c('img',{staticClass:"invoice-img",attrs:{"src":_vm.payment.url ? _vm.payment.url : 'https://firebasestorage.googleapis.com/v0/b/test-goguau.appspot.com/o/general%2Finvoice.jpg?alt=media&token=926c0acc-b1bd-48a1-a5eb-3be3beada190',"alt":""}}),_c('b-form-file',{attrs:{"placeholder":"Cargar img de recibo .jpg, .png, .jpeg","drop-placeholder":"Coloca tu foto aquí...","accept":".jpg, .png, .jpeg","browse-text":"Buscar"},on:{"input":_vm.updateURL},model:{value:(_vm.$v.payment.img.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.img, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.img.$model"}})],1)],1)],1),_c('br'),_c('b-row',[(_vm.$v.payment.img.$model != null)?_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"method"}},[_vm._v("Método de pago *")]),_c('b-form-select',{class:{
                  'form-control is-invalid': _vm.$v.payment.methodSelected.$error,
                  'form-control is-valid': !_vm.$v.payment.methodSelected.$invalid
                },attrs:{"id":"method","plain":true,"options":_vm.methodOptions},model:{value:(_vm.$v.payment.methodSelected.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.methodSelected, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.methodSelected.$model"}}),(!_vm.$v.payment.methodSelected.required)?_c('b-form-invalid-feedback',[_vm._v(" El método de pago es obligatorio ")]):_vm._e()],1)],1):_vm._e(),(_vm.$v.payment.img.$model != null)?_c('b-col',{attrs:{"sm":"6"}},[_vm._v(" Fecha de pago * "),_c('b-form-group',[_c('b-form-datepicker',{class:{
                  'form-control is-invalid':_vm.$v.payment.date.$error,
                  'form-control is-valid': !_vm.$v.payment.date.$invalid
                },attrs:{"id":"paymentDate","placeholder":"dd/mm/yyyy","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                },"locale":"es","today-button":"","reset-button":""},model:{value:(_vm.$v.payment.date.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.date, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.date.$model"}}),(!_vm.$v.payment.date.required)?_c('b-form-invalid-feedback',[_vm._v(" La fecha de pago del servicio es obligatoria ")]):_vm._e()],1)],1):_vm._e(),(_vm.$v.payment.img.$model != null)?_c('b-col',{attrs:{"lg":"6"}},[_vm._v(" Id de pago * "),_c('b-form-group',[_c('b-form-input',{class:{
                  'form-control is-invalid': _vm.$v.payment.code.$error,
                  'form-control is-valid': !_vm.$v.payment.code.$invalid
                },attrs:{"type":"text"},model:{value:(_vm.$v.payment.code.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.code, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.code.$model"}}),(!_vm.$v.payment.code.required)?_c('b-form-invalid-feedback',[_vm._v(" Inserta el id de pago ")]):_vm._e()],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_vm._v(" Estado del pago * "),_c('v-select',{class:{
                  'form-control is-invalid': _vm.$v.payment.statusSelected.$error,
                  'form-control is-valid': !_vm.$v.payment.statusSelected.$invalid
                },attrs:{"options":_vm.statusOptions,"placeholder":"Selecciona"},model:{value:(_vm.payment.statusSelected),callback:function ($$v) {_vm.$set(_vm.payment, "statusSelected", $$v)},expression:"payment.statusSelected"}}),(!_vm.$v.payment.statusSelected.required)?_c('b-form-invalid-feedback',[_vm._v(" El estado del pago es obligatorio ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_vm._v(" Monto recibido ($) * "),_c('b-form-input',{class:{
                  'form-control is-invalid': _vm.$v.payment.amount.$error,
                  'form-control is-valid': !_vm.$v.payment.amount.$invalid
                },attrs:{"type":"number","id":"amount","placeholder":"Ingresa valor","min":"0"},model:{value:(_vm.$v.payment.amount.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.amount, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.amount.$model"}}),(!_vm.$v.payment.amount.required)?_c('b-form-invalid-feedback',[_vm._v(" El monto total es obligatorio ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_vm._v(" Debe ($) * "),_c('b-form-input',{attrs:{"id":"amount","type":"number","min":"0","placeholder":"Ingresa valor"},model:{value:(_vm.$v.payment.owes.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.owes, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.owes.$model"}})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_vm._v(" Comentarios "),_c('CTextarea',{attrs:{"placeholder":"Cualquier anotación que desees registrar...","rows":"2"},model:{value:(_vm.payment.comments),callback:function ($$v) {_vm.$set(_vm.payment, "comments", $$v)},expression:"payment.comments"}})],1)],1)],1)],1)],1),_c('CCard',{staticClass:"mb-0",staticStyle:{"margin-top":"0.5rem"}},[_c('CButton',{staticClass:"text-left shadow-none card-header",attrs:{"block":"","color":"link"},on:{"click":function($event){_vm.accordionType = _vm.accordionType === 0 ? false : 0}}},[_c('h5',{staticClass:"m-0"},[_vm._v("Tipo de servicio")])]),_c('CCollapse',{attrs:{"show":_vm.accordionType === 0}},[_c('CCardBody',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_vm._v(" Servicio * "),_c('v-select',{class:{
                  'form-control is-invalid':
                    _vm.$v.payment.serviceSelected.$error,
                  'form-control is-valid': !_vm.$v.payment.serviceSelected
                    .$invalid
                },attrs:{"options":_vm.servicesOptions,"placeholder":"Selecciona"},model:{value:(_vm.payment.serviceSelected),callback:function ($$v) {_vm.$set(_vm.payment, "serviceSelected", $$v)},expression:"payment.serviceSelected"}}),(!_vm.$v.payment.serviceSelected.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes seleccionar un servicio ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_vm._v(" Tipo * "),_c('v-select',{class:{
                  'form-control is-invalid': _vm.$v.payment.typeSelected.$error,
                  'form-control is-valid': !_vm.$v.payment.typeSelected.$invalid
                },attrs:{"options":_vm.typeOptions,"placeholder":"Selecciona"},model:{value:(_vm.payment.typeSelected),callback:function ($$v) {_vm.$set(_vm.payment, "typeSelected", $$v)},expression:"payment.typeSelected"}}),(!_vm.$v.payment.typeSelected.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes seleccionar un servicio ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_vm._v(" Cantidad de servicios * "),_c('b-form-input',{class:{
                  'form-control is-invalid': _vm.$v.payment.quantity.$error,
                  'form-control is-valid': !_vm.$v.payment.quantity.$invalid
                },attrs:{"id":"quantity","type":"number","min":"1","placeholder":""},model:{value:(_vm.$v.payment.quantity.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.quantity, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.quantity.$model"}}),(!_vm.$v.payment.quantity.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes indicar la ctdad de servicios. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_vm._v(" Cantidad de Mascotas * "),_c('b-form-input',{class:{
                  'form-control is-invalid': _vm.$v.payment.pets.$error,
                  'form-control is-valid': !_vm.$v.payment.pets.$invalid
                },attrs:{"id":"pets","type":"number","min":"1","placeholder":""},model:{value:(_vm.$v.payment.pets.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.pets, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.pets.$model"}}),(!_vm.$v.payment.pets.required)?_c('b-form-invalid-feedback',[_vm._v(" Debes indicar la ctdad de servicios. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_vm._v(" Fecha de inicio del servicio * "),_c('b-form-group',[_c('b-form-datepicker',{class:{
                  'form-control is-invalid':_vm.$v.payment.serviceStartDate.$error,
                  'form-control is-valid': !_vm.$v.payment.serviceStartDate.$invalid
                },attrs:{"id":"serviceStartDate","placeholder":"dd/mm/yyyy","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                },"locale":"es","today-button":"","reset-button":""},model:{value:(_vm.$v.payment.serviceStartDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.payment.serviceStartDate, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.payment.serviceStartDate.$model"}}),(!_vm.$v.payment.serviceStartDate.required)?_c('b-form-invalid-feedback',[_vm._v(" La fecha de inicio del servicio ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[(_vm.planInfo[0] != null)?_c('b-form-group',[_vm._v(" Precio und: "),_c('b-form-input',{attrs:{"type":"number","id":"quantity","min":"0","placeholder":""},model:{value:(_vm.planInfo[0].precio),callback:function ($$v) {_vm.$set(_vm.planInfo[0], "precio", $$v)},expression:"planInfo[0].precio"}})],1):_vm._e()],1)],1),_c('b-row',[_c('br')]),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-button',{on:{"click":_vm.submit}},[_vm._v(" Agregar ")])],1),(_vm.flagBtnShow)?_c('b-col',{attrs:{"lg":"6"}},[_c('b-button',{on:{"click":_vm.showServicesFixed}},[_vm._v(" Ver servicios ")])],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }